import { createContext, useState } from 'react';
import { useLocation } from 'react-router';

import { Info } from '../../types/Data';
import { StudentUrlOptions } from '../../types/UrlOptions';

type IndividualStudentInfo = {
	name: string;
	contactNumber: string;
	id: string;
};

type StudentViewContextValue = {
	studentInfo: IndividualStudentInfo | undefined;
	viewType: StudentUrlOptions;
	onClose: () => void;
	onRowClick: (data: Info) => void;
	onTabsChange: (value: string) => void;
};

type StudentViewProviderProps = {
	children: React.ReactNode;
};

export const StudentViewContext = createContext<
	StudentViewContextValue | undefined
>(undefined);

export const StudentViewProvider = ({ children }: StudentViewProviderProps) => {
	const [studentInfo, setStudentInfo] = useState<IndividualStudentInfo>();
	const [viewType, setViewType] = useState<StudentUrlOptions>('Exemptions');
	const { pathname } = useLocation();

	const getViewTypeName = async () => {
		let path = pathname.replace('/', '');
		if (
			path.toLowerCase() === 'students' ||
			path.toLowerCase().includes('cancellations') ||
			path.toLowerCase().includes('logbook')
		) {
			path = 'Results';
		}
		return path as StudentUrlOptions;
	};

	const setData = (data: Info) => {
		if ('Student' in data) {
			return setStudentInfo({
				id: data.Student.ID,
				name: data.StudentName,
				contactNumber: data.StudentContactNumber,
			});
		}
		if ('ID' in data) {
			return setStudentInfo({
				id: data.ID,
				name: `${data.FirstName} ${data.LastName}`,
				contactNumber: data.ContactNumber,
			});
		}
	};

	const onRowClick = async (data: Info) => {
		const path = await getViewTypeName();
		setViewType(path);
		setData(data);
	};

	const onTabsChange = (value: string) => {
		setViewType(value as StudentUrlOptions);
	};

	const onClose = () => {
		setStudentInfo(undefined);
	};

	return (
		<StudentViewContext.Provider
			value={{ studentInfo, viewType, onRowClick, onTabsChange, onClose }}
		>
			{children}
		</StudentViewContext.Provider>
	);
};
