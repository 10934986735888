import { createElement, forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './MonthPickerStyle.css';

const minDate = new Date('January 01 2018 00:00:00');
const maxDate = new Date();

const MonthPickerButton = (
	props: React.ComponentProps<'button'>,
	ref: React.Ref<HTMLButtonElement>
) => {
	const { type = 'button' } = props;
	return (
		<button ref={ref} id="month-picker" type={type} {...props}>
			{`Release month: ${props.value}`}
		</button>
	);
};

type MonthPickerProps = {
	resultMonth: Date;
	setResultMonth: (date: Date) => void;
};

export const MonthPicker = ({
	resultMonth,
	setResultMonth,
}: MonthPickerProps) => (
	<DatePicker
		selected={resultMonth}
		closeOnScroll={e => e.target === document}
		onChange={date => {
			if (date) {
				setResultMonth(date);
			}
		}}
		dateFormat="MM/yyyy"
		showMonthYearPicker
		minDate={minDate}
		maxDate={maxDate}
		showDisabledMonthNavigation
		calendarClassName="innerCalender"
		className="icas-button secondary large month-picker"
		customInput={createElement(forwardRef(MonthPickerButton))}
	/>
);
