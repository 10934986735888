import { SortingFn } from '@tanstack/react-table';

import { Info } from '../types/Data';

export const renderGBP = (price: number) => {
	const formatter = new Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: 'GBP',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return formatter.format(price);
};

export const renderDate = (dateString: string) => {
	const dateArray = dateString.split('/');
	const day = parseInt(dateArray[0]);
	const month = parseInt(dateArray[1]);
	const year = parseInt(dateArray[2]);
	return new Date(year, month, day);
};

export const datetimeSorting: SortingFn<Info> = (
	rowA: { getValue: (arg0: string) => string },
	rowB: { getValue: (arg0: string) => string },
	columnId: string
) => {
	let a: Date | number = 0;
	let b: Date | number = 0;
	if (rowA.getValue(columnId)) {
		a = renderDate(rowA.getValue(columnId));
	}
	if (rowB.getValue(columnId)) {
		b = renderDate(rowB.getValue(columnId));
	}

	// Can handle nullish values
	// Use > and < because == (and ===) doesn't work with
	// Date objects (would require calling getTime()).
	return a > b ? 1 : a < b ? -1 : 0;
};

export const renderCapitalisedString = (string?: string) => {
	if (!string || !string?.length) {
		return;
	}
	const words = string.split(' ');
	const formatWords = words.map(word => {
		const firstLetter = word.slice(0, 1).toUpperCase();
		const restOfWord = word.slice(1).toLowerCase();
		return firstLetter.concat(restOfWord);
	});
	return formatWords.join(' ');
};

export const renderBooleanString = (boolean?: boolean) =>
	boolean ? 'Yes' : 'No';

type ShortBreakdownNamesType = Record<string, string>;
export const shortBreakdownNames: ShortBreakdownNamesType = {
	'Assessment One': 'A1',
	'Assessment Two': 'A2',
	'Assessment Three': 'A3',
	Technical: 'Tech',
	'Technical Grade': 'Tech',
	Professional: 'Pro',
	'Professional Grade': 'Pro',
	'Presentation Grade': 'Prtn',
};

export const renderShortBreakdownName = (name: string): string => {
	if (name in shortBreakdownNames) {
		return shortBreakdownNames[name];
	}
	return name.replace(' ', '');
};

type RenderUrlProps = {
	id: string;
	url: string;
	levelCode?: string;
	examTypeFilter?: string;
	dateFilter?: Date;
};

export const renderUrl = ({
	id,
	url,
	levelCode,
	examTypeFilter,
	dateFilter,
}: RenderUrlProps) => {
	let newUrl = url.replace('{id}', id);
	if (levelCode) {
		newUrl = newUrl.replace('{level}', `${levelCode}`);
	}
	if (!levelCode && newUrl.includes('{level}')) {
		newUrl = newUrl.replace('levelCodeFilter={level}', '');
	}
	if (examTypeFilter) {
		newUrl = newUrl.replace('{exam}', `${examTypeFilter}`);
	}
	if (dateFilter) {
		newUrl = newUrl.replace('{date}', `${dateFilter.toISOString()}`);
	}
	if (newUrl.includes('?&')) {
		newUrl = newUrl.replace('&', '');
	}
	return newUrl;
};

export const stripData = (data: Record<string, unknown>[]) =>
	data.map(record =>
		Object.fromEntries(
			Object.entries(record)
				.filter(
					([key, value]) =>
						!key.toLowerCase().includes('id') &&
						(value === null || typeof value !== 'object')
				)
				.map(([key, value]) => [formatHeader(key), value])
		)
	);

export const formatHeader = (string: string) => {
	if (!string || !string?.length) {
		return;
	}
	return string.split(/(?=[A-Z])/).join(' ');
};
