import { useMemo, useState } from 'react';
import { Content, useExportableCSV } from 'use-exportable-csv';

import { LoadingStatus } from '@icasdigital/icas.core.reactcomponents';

import { Columns } from '../../constants/columns';
import { EMPTY_DATA, STUDENT_URLS } from '../../constants/constants';
import { useGetData } from '../../hooks/useGetData';
import { useLevel } from '../../hooks/useLevel';
import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { useTable } from '../../hooks/useTable';
import { ClassEnrolmentType } from '../../pages/ClassEnrolments/ClassEnrolments';
import { renderUrl, stripData } from '../../utils/Formatting';
import { DownloadButton } from '../Actions/DownloadButton';
import { LevelSelect } from '../Actions/LevelSelect';
import { PageSizeSelect } from '../Actions/PageSizeSelect';
import { SearchBox } from '../Actions/SearchBox';
import { Pagination } from '../Table/Pagination';
import { DataTable } from '../Table/Table';
import { Tabs } from '../Tabs/Tabs';
import { TabsContent, TabsList, TabsTrigger } from '../Tabs/TabsItems';

export const ClassEnrolmentsStudentView = () => {
	const { studentInfo } = useStudentViewContext();

	const { level, setLevel } = useLevel();
	const [enrolmentType, setEnrolmentType] =
		useState<ClassEnrolmentType>('Parent');

	const urlNeeded = useMemo(
		() =>
			enrolmentType === 'Parent'
				? STUDENT_URLS.ParentClassEnrolments
				: STUDENT_URLS.ClassEnrolments,
		[enrolmentType]
	);

	const url = useMemo(
		() =>
			!studentInfo
				? ''
				: renderUrl({
						id: studentInfo.id,
						url: urlNeeded,
						levelCode: level,
				  }),
		[studentInfo, urlNeeded, level]
	);

	const { data, setLoadingStatus } = useGetData({ url });
	const { table, globalFilter, setGlobalFilter } = useTable({
		columns: Columns.ClassEnrolments,
		data,
	});

	const options = { bom: true };
	const noData = !data || !data.length;
	const csvData = noData ? EMPTY_DATA : stripData(data);
	const csvLink = useExportableCSV(csvData as Content, options);

	if (!studentInfo || !data) {
		return null;
	}

	const onLevelChange = (level?: string) => {
		setLevel(level);
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	const onTabsChange = (value: string) => {
		setGlobalFilter('');
		setEnrolmentType(value as ClassEnrolmentType);
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	const downloadName = `${studentInfo.name}-class-enrolments`;
	return (
		<div className="student-view-container with-tabs">
			<div className="filter-container">
				<div style={{ display: 'flex', gap: '24px' }}>
					<SearchBox
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
					<PageSizeSelect table={table} />
					<LevelSelect currentLevel={level} setLevel={onLevelChange} />
				</div>
				<DownloadButton csvLink={csvLink} dataName={downloadName} />
			</div>
			<Tabs value={enrolmentType} onValueChange={onTabsChange}>
				<TabsList>
					<TabsTrigger value={'Parent'}>Course</TabsTrigger>
					<TabsTrigger value={'Class'}>Class</TabsTrigger>
				</TabsList>
				<TabsContent value={'Parent'}>
					<DataTable table={table} className="first-tab-child" />
				</TabsContent>
				<TabsContent value={'Class'}>
					<DataTable table={table} />
				</TabsContent>
			</Tabs>
			<Pagination table={table} />
		</div>
	);
};
