import { Button, VisuallyHidden } from '@icasdigital/icas.core.reactcomponents';

import { TableProps } from './Table';

type PaginationProps = Pick<TableProps, 'table'>;

export const Pagination = ({ table }: PaginationProps) => (
	<div className="pagination-container">
		<Button
			id="pagination-start"
			mode="icon"
			onClick={() => table.setPageIndex(0)}
			disabled={!table.getCanPreviousPage()}
			text="<<"
			ariaDescribedById="pagination-start-desc"
		/>
		<VisuallyHidden as="p" id="pagination-start-desc">
			Back to First Page
		</VisuallyHidden>
		<Button
			id="pagination-back"
			style={{ width: '8rem' }}
			onClick={() => table.previousPage()}
			disabled={!table.getCanPreviousPage()}
			text="Previous"
		/>
		<span className="table-count">
			<div>Page</div>
			<strong>
				{table.getState().pagination.pageIndex + 1} of{' '}
				{Math.max(table.getPageCount(), 1)}
			</strong>
		</span>
		<Button
			id="pagination-next"
			style={{ width: '8rem' }}
			onClick={() => table.nextPage()}
			disabled={!table.getCanNextPage()}
			text="Next"
		/>
		<Button
			id="pagination-last"
			mode="icon"
			onClick={() => table.setPageIndex(table.getPageCount() - 1)}
			disabled={!table.getCanNextPage()}
			text=">>"
			ariaDescribedById="pagination-last-desc"
		/>
		<VisuallyHidden as="p" id="pagination-last-desc">
			Last Page
		</VisuallyHidden>
	</div>
);
