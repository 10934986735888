import { Dispatch, SetStateAction } from 'react';

import { TextInput } from '@icasdigital/icas.core.reactcomponents';

type SearchBoxProps = {
	globalFilter: string;
	setGlobalFilter: Dispatch<SetStateAction<string>>;
};

export const SearchBox = ({
	globalFilter,
	setGlobalFilter,
}: SearchBoxProps) => (
	<TextInput
		id={'search'}
		labelText={'Search all columns:'}
		value={globalFilter}
		onChange={e => setGlobalFilter(e.currentTarget.value)}
	/>
);
