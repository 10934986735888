import { NavLink } from 'react-router-dom';

import { MenuArrow } from './MenuArrow';

type MenuItemProps = {
	to: string;
	children: string;
};

export const MenuItem = ({ to, children }: MenuItemProps) => (
	<li>
		<NavLink to={to} key={to}>
			{children}
			<MenuArrow />
		</NavLink>
	</li>
);
