import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OfficeIcon = () => (
	<FontAwesomeIcon
		icon={faBuilding}
		size="2xl"
		style={{
			marginTop: 'auto',
			marginBottom: 'auto',
			marginRight: '16px',
		}}
	/>
);
