import React from 'react';

import * as TabsComponent from '@radix-ui/react-tabs';

import './TabsStyle.css';

export type ICASTabsProps = TabsComponent.TabsProps & {
	value: string;
	children: React.ReactNode;
};

export const Tabs = ({ value, children, ...rest }: ICASTabsProps) => (
	<TabsComponent.Root defaultValue={value} className="tabs-root" {...rest}>
		{children}
	</TabsComponent.Root>
);
