import { useContext } from 'react';

import { RoleContext } from '../components/RoleContextProvider';

export const useRoleContext = () => {
	const context = useContext(RoleContext);
	if (!context) {
		throw new Error('role context is not provided');
	}
	return context;
};
