import { useExportableCSV } from 'use-exportable-csv';

import {
	LoadingLottie,
	LoadingStatus,
} from '@icasdigital/icas.core.reactcomponents';

import { DownloadButton } from '../../components/Actions/DownloadButton';
import { PageSizeSelect } from '../../components/Actions/PageSizeSelect';
import { SearchBox } from '../../components/Actions/SearchBox';
import { Loader } from '../../components/Loader';
import { StudentView } from '../../components/StudentView/StudentView';
import { Pagination } from '../../components/Table/Pagination';
import { DataTable } from '../../components/Table/Table';
import { Columns } from '../../constants/columns';
import { EMPTY_DATA, ORGANISATION_URLS } from '../../constants/constants';
import { useGetData } from '../../hooks/useGetData';
import { useRoleContext } from '../../hooks/useRoleContext';
import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { useTable } from '../../hooks/useTable';
import { renderUrl, stripData } from '../../utils/Formatting';

export const LogbookProgressions = () => {
	const { studentInfo } = useStudentViewContext();
	const showStudentView = studentInfo?.id !== undefined;

	const { role } = useRoleContext();
	const url = !role
		? ''
		: renderUrl({
				id: role.OrganisationId,
				url: ORGANISATION_URLS.LogbookProgressions,
		  });

	const { data, loadingStatus } = useGetData({ url });
	const { table, globalFilter, setGlobalFilter } = useTable({
		columns: Columns.LogbookProgressions,
		data,
	});

	const options = { bom: true };
	const noData = !data || !data.length;
	const csvData = noData ? EMPTY_DATA : stripData(data);
	const csvLink = useExportableCSV(csvData, options);

	const isNotLoaded =
		loadingStatus !== LoadingStatus.LoadedSuccessfully &&
		loadingStatus !== LoadingStatus.EndedWithError;

	if (isNotLoaded && !data) {
		return <Loader />;
	}

	return (
		<div className="app-container">
			{showStudentView ? <StudentView /> : null}
			<div>
				<h2 className="with-line">Logbook Progressions</h2>
				<p>
					Below you will find an overview of the logbook progressions of your
					current students. The following acronyms are used on the table - SA
					Days for Statutory Audit Days and RPE days for Relevant Practical
					Experience Days.
				</p>
			</div>
			<div className="filter-container">
				{noData ? <p className="no-results">No results found</p> : null}
				<div style={{ display: 'flex', gap: '24px' }}>
					<SearchBox
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
					<PageSizeSelect table={table} />
				</div>
				<DownloadButton csvLink={csvLink} dataName="LogbookProgressions" />
			</div>
			<DataTable table={table} />
			<Pagination table={table} />
		</div>
	);
};
