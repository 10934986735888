import { useMemo } from 'react';
import { Content, useExportableCSV } from 'use-exportable-csv';

import { LoadingStatus } from '@icasdigital/icas.core.reactcomponents';

import { Columns } from '../../constants/columns';
import { EMPTY_DATA, STUDENT_URLS } from '../../constants/constants';
import { useGetData } from '../../hooks/useGetData';
import { useLevel } from '../../hooks/useLevel';
import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { useTable } from '../../hooks/useTable';
import { renderUrl, stripData } from '../../utils/Formatting';
import { DownloadButton } from '../Actions/DownloadButton';
import { LevelSelect } from '../Actions/LevelSelect';
import { PageSizeSelect } from '../Actions/PageSizeSelect';
import { SearchBox } from '../Actions/SearchBox';
import { Pagination } from '../Table/Pagination';
import { DataTable } from '../Table/Table';

export const CourseEnrolmentsStudentView = () => {
	const { studentInfo } = useStudentViewContext();

	const { level, setLevel } = useLevel();

	const url = useMemo(
		() =>
			!studentInfo
				? ''
				: renderUrl({
						id: studentInfo.id,
						url: STUDENT_URLS.CourseEnrolments,
						levelCode: level,
				  }),
		[studentInfo, level]
	);

	const { data, setLoadingStatus } = useGetData({ url });
	const { table, globalFilter, setGlobalFilter } = useTable({
		columns: Columns.CourseEnrolments,
		data,
	});

	const options = { bom: true };
	const noData = !data || !data.length;
	const csvData = noData ? EMPTY_DATA : stripData(data);
	const csvLink = useExportableCSV(csvData as Content, options);

	if (!studentInfo || !data) {
		return null;
	}

	const onLevelChange = (level?: string) => {
		setLevel(level);
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	const downloadName = `${studentInfo.name}-course-enrolments`;
	return (
		<div className="student-view-container">
			<div className="filter-container">
				<div style={{ display: 'flex', gap: '24px' }}>
					<SearchBox
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
					<PageSizeSelect table={table} />
					<LevelSelect currentLevel={level} setLevel={onLevelChange} />
				</div>
				<DownloadButton csvLink={csvLink} dataName={downloadName} />
			</div>
			<DataTable table={table} />
			<Pagination table={table} />
		</div>
	);
};
