import { createContext, useEffect, useRef, useState } from 'react';

import { useUser } from '../hooks/useUser';
import { Role } from '../types/User';

type RoleContextValue = {
	role: Role | undefined;
	userRoles: Role[] | undefined;
	roleChoiceNeeded: boolean | undefined;
	handleRoleChoice: (role: Role) => void;
	setRoleChoiceNeeded: (boolean: boolean) => void;
	isOldSyllabusRole: boolean | undefined;
};

type RoleProviderProps = {
	children: React.ReactNode;
};

export const RoleContext = createContext<RoleContextValue | undefined>(
	undefined
);

const oldSyllabusOrganisations = 'Ernst & Young';

export const RoleContextProvider = ({ children }: RoleProviderProps) => {
	const { user } = useUser();
	const chosenRole = useRef<Role>();
	const [roleChoiceNeeded, setRoleChoiceNeeded] = useState<boolean>();
	const isOldSyllabusRole = chosenRole.current?.OrganisationName.includes(
		oldSyllabusOrganisations
	);

	useEffect(() => {
		if (!user) {
			return;
		}

		const { Roles: userRoles } = user;

		if (!userRoles || !userRoles.length) {
			return;
		}
		const userHasMultipleRoles = userRoles?.length > 1;

		if (!chosenRole.current && userHasMultipleRoles) {
			setRoleChoiceNeeded(true);
		}
		if (!chosenRole.current && !userHasMultipleRoles) {
			chosenRole.current = userRoles[0];
			setRoleChoiceNeeded(false);
		}
	}, [user]);

	const handleRoleChoice = (role: Role) => {
		chosenRole.current = role;
		setRoleChoiceNeeded(false);
	};

	return (
		<RoleContext.Provider
			value={{
				role: chosenRole.current,
				userRoles: user?.Roles,
				roleChoiceNeeded,
				handleRoleChoice,
				setRoleChoiceNeeded,
				isOldSyllabusRole,
			}}
		>
			{children}
		</RoleContext.Provider>
	);
};
