import { useMemo, useState } from 'react';
import { Content, useExportableCSV } from 'use-exportable-csv';

import { LoadingStatus } from '@icasdigital/icas.core.reactcomponents';

import { Columns } from '../../constants/columns';
import { EMPTY_DATA, STUDENT_URLS } from '../../constants/constants';
import { useGetData } from '../../hooks/useGetData';
import { useLevel } from '../../hooks/useLevel';
import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { useTable } from '../../hooks/useTable';
import { renderUrl, stripData } from '../../utils/Formatting';
import { DownloadButton } from '../Actions/DownloadButton';
import { LevelSelect } from '../Actions/LevelSelect';
import { PageSizeSelect } from '../Actions/PageSizeSelect';
import { SearchBox } from '../Actions/SearchBox';
import { Pagination } from '../Table/Pagination';
import { DataTable } from '../Table/Table';
import { Tabs } from '../Tabs/Tabs';
import { TabsContent, TabsList, TabsTrigger } from '../Tabs/TabsItems';

export const ResultsStudentView = () => {
	const [resultType, setResultType] = useState('Exam');
	const { studentInfo } = useStudentViewContext();

	const { level, setLevel } = useLevel();

	const url = useMemo(
		() =>
			!studentInfo?.id
				? ''
				: renderUrl({
						id: studentInfo.id,
						url: STUDENT_URLS.Results,
						levelCode: level,
						examTypeFilter: resultType,
				  }),
		[studentInfo, level, resultType]
	);

	const { data, setLoadingStatus } = useGetData({ url });
	const { table, globalFilter, setGlobalFilter } = useTable({
		columns: Columns.Results,
		data,
	});

	const options = { bom: true };
	const noData = !data || !data.length;
	const csvData = noData ? EMPTY_DATA : stripData(data);
	const csvLink = useExportableCSV(csvData as Content, options);

	if (!studentInfo || !data) {
		return null;
	}

	const onTabsChange = (value: string) => {
		setGlobalFilter('');
		setResultType(value);
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	const onLevelChange = (level?: string) => {
		setLevel(level);
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	const downloadName = `${studentInfo.name}-results`;
	return (
		<div className="student-view-container with-tabs">
			<div className="filter-container">
				<div style={{ display: 'flex', gap: '24px' }}>
					<SearchBox
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
					<PageSizeSelect table={table} />
					<LevelSelect currentLevel={level} setLevel={onLevelChange} />
				</div>
				<DownloadButton csvLink={csvLink} dataName={downloadName} />
			</div>
			<Tabs value={resultType} onValueChange={onTabsChange}>
				<TabsList>
					<TabsTrigger value="Exam">Exam</TabsTrigger>
					<TabsTrigger value="Mock">Mock</TabsTrigger>
					<TabsTrigger value="TOAK">TOAK</TabsTrigger>
					<TabsTrigger value="PCT">PCT</TabsTrigger>
					<TabsTrigger value="HEX">HEX</TabsTrigger>
					<TabsTrigger value="MEX">MEX</TabsTrigger>
					<TabsTrigger value="PT">PT</TabsTrigger>
					<TabsTrigger value="PM">PM</TabsTrigger>
					<TabsTrigger value="PQ">PQ</TabsTrigger>
				</TabsList>
				<TabsContent value="Exam">
					<DataTable table={table} className="first-tab-child" />
				</TabsContent>
				<TabsContent value="Mock">
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value="TOAK">
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value="PCT">
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value="HEX">
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value="MEX">
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value="PT">
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value="PM">
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value="PQ">
					<DataTable table={table} />
				</TabsContent>
			</Tabs>
			<Pagination table={table} />
		</div>
	);
};
