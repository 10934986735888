import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FirmIcon = () => (
	<FontAwesomeIcon
		icon={faSitemap}
		size="2xl"
		style={{
			marginTop: 'auto',
			marginBottom: 'auto',
			marginRight: '16px',
		}}
	/>
);
