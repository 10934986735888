import { useContext } from 'react';

import { StudentViewContext } from '../components/StudentView/StudentViewProvider';

export const useStudentViewContext = () => {
	const context = useContext(StudentViewContext);
	if (!context) {
		throw new Error(
			'Student view cannot be rendered outside the student view context'
		);
	}
	return context;
};
