import { useAuthContext } from '@icasdigital/icas.core.reactcomponents';

import { MENU_ITEMS } from '../../constants/constants';
import { useRoleContext } from '../../hooks/useRoleContext';

import { MenuItem } from './MenuItem';

import './MenuStyle.css';

export const Menu = () => {
	const { userId } = useAuthContext();

	const { role, isOldSyllabusRole } = useRoleContext();

	if (!userId || !role) {
		return null;
	}

	return (
		<nav className="side-menu">
			<ul>
				<MenuItem to={MENU_ITEMS.Students.path}>
					{MENU_ITEMS.Students.displayText}
				</MenuItem>
				<MenuItem to={MENU_ITEMS.Exemptions.path}>
					{MENU_ITEMS.Exemptions.displayText}
				</MenuItem>
				{isOldSyllabusRole ? (
					<>
						<MenuItem to={MENU_ITEMS.ClassEnrolments.path}>
							{MENU_ITEMS.ClassEnrolments.displayText}
						</MenuItem>
						<MenuItem to={MENU_ITEMS.ClassCancellations.path}>
							{MENU_ITEMS.ClassCancellations.displayText}
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem to={MENU_ITEMS.CourseEnrolments.path}>
							{MENU_ITEMS.CourseEnrolments.displayText}
						</MenuItem>
						<MenuItem to={MENU_ITEMS.CourseCancellations.path}>
							{MENU_ITEMS.CourseCancellations.displayText}
						</MenuItem>
					</>
				)}
				<MenuItem to={MENU_ITEMS.FundamentalEnrolments.path}>
					{MENU_ITEMS.FundamentalEnrolments.displayText}
				</MenuItem>
				<MenuItem to={MENU_ITEMS.Absences.path}>
					{MENU_ITEMS.Absences.displayText}
				</MenuItem>
				<MenuItem to={MENU_ITEMS.ExamEnrolments.path}>
					{MENU_ITEMS.ExamEnrolments.displayText}
				</MenuItem>
				<MenuItem to={MENU_ITEMS.ExamCancellations.path}>
					{MENU_ITEMS.ExamCancellations.displayText}
				</MenuItem>
				<MenuItem to={MENU_ITEMS.Results.path}>
					{MENU_ITEMS.Results.displayText}
				</MenuItem>
				<MenuItem to={MENU_ITEMS.LogbookProgressions.path}>
					{MENU_ITEMS.LogbookProgressions.displayText}
				</MenuItem>
				<MenuItem to={MENU_ITEMS.OfficeRoles.path}>
					{MENU_ITEMS.OfficeRoles.displayText}
				</MenuItem>
			</ul>
		</nav>
	);
};
