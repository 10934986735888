import { useNavigate } from 'react-router-dom';

import { Button, Modal } from '@icasdigital/icas.core.reactcomponents';

import { useRoleContext } from '../../hooks/useRoleContext';
import { Role } from '../../types/User';
import { FirmIcon } from '../FirmIcon';
import { OfficeIcon } from '../OfficeIcon';

import './RolePickerStyle.css';

type RoleChoiceProps = {
	role: Role;
};

const RoleChoice = ({ role }: RoleChoiceProps) => {
	const { handleRoleChoice } = useRoleContext();
	const navigate = useNavigate();
	const {
		OrganisationName: name,
		OrganisationCity: city,
		OrganisationType: type,
	} = role;
	const icon = type === 'Office' ? <OfficeIcon /> : <FirmIcon />;

	const ariaText = `see data for ${name} ${city}`;

	const onClick = () => {
		handleRoleChoice(role);
		if (!window.location.href.includes('Students')) {
			navigate('/Students');
		}
	};

	return (
		<div className="roles-container">
			<div className="roles-info" aria-hidden>
				{icon}
				<div>
					<h3>
						{name} - {city}
					</h3>
					<p>Access: {type}</p>
				</div>
			</div>
			<div className="roles-button">
				<Button
					id={`${name}-button`}
					text={'Select'}
					aria-label={ariaText}
					onClick={onClick}
				/>
			</div>
		</div>
	);
};

export const RolePicker = () => {
	const { roleChoiceNeeded, userRoles } = useRoleContext();
	if (!userRoles || !roleChoiceNeeded) {
		return null;
	}
	return (
		<Modal id="role-picker" onConfirm={() => {}} onClose={() => {}}>
			<Modal.Header>Select office:</Modal.Header>
			<Modal.Body>
				Please choose which organisation you would like to see data for. There
				are two different types of access - &apos;firm&apos; access where you
				can see data to that office and all child offices and &apos;office&apos;
				access where you only see data for that office. If you do not see a firm
				that you should have access to please contact ICAS.
			</Modal.Body>
			<fieldset>
				{userRoles.map(role => (
					<RoleChoice key={role.OrganisationId} role={role} />
				))}
			</fieldset>
		</Modal>
	);
};
