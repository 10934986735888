import { AnchorButton } from '@icasdigital/icas.core.reactcomponents';

type DownloadButtonProps = {
	dataName: string;
	csvLink: string;
};

export const DownloadButton = ({ dataName, csvLink }: DownloadButtonProps) => (
	<AnchorButton
		id="download"
		text="Download"
		style={{ width: '9em' }}
		size="large"
		href={csvLink}
		download={`${dataName}-Data.csv`}
	/>
);
