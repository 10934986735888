import React from 'react';

import * as TabsComponent from '@radix-ui/react-tabs';

import { ICASTabsProps } from './Tabs';

import './TabsStyle.css';

type TabsListProps = Omit<ICASTabsProps, 'value'>;

export const TabsList = ({ children }: TabsListProps) => (
	<TabsComponent.List className="tabs-list">{children}</TabsComponent.List>
);

export const TabsContent = ({ value, children }: ICASTabsProps) => (
	<TabsComponent.Content value={value}>{children}</TabsComponent.Content>
);

export const TabsTrigger = ({ value, children }: ICASTabsProps) => (
	<TabsComponent.Trigger value={value} className="tabs-trigger">
		{children}
	</TabsComponent.Trigger>
);
