import { LoadingLottie } from '@icasdigital/icas.core.reactcomponents';

export const Loader = () => (
	<div
		style={{
			height: '100vh',
			marginLeft: 'auto',
			marginRight: 'auto',
			marginTop: '4rem',
		}}
	>
		<LoadingLottie />
	</div>
);
