import ReactDOM from 'react-dom/client';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Route,
	RouterProvider,
} from 'react-router-dom';

import { PrivateRoute } from '@icasdigital/icas.core.reactcomponents';

import { ALLOWED_WEB_ROLES } from './constants/constants';
import { Absences } from './pages/Absences/Absences';
import { ClassCancellation } from './pages/ClassCancellations/ClassCancellations';
import { ClassEnrolments } from './pages/ClassEnrolments/ClassEnrolments';
import { CourseCancellation } from './pages/CourseCancellations/CourseCancellations';
import { CourseEnrolments } from './pages/CourseEnrolments/CourseEnrolments';
import { ExamCancellations } from './pages/ExamCancellations/ExamCancellations';
import { ExamEnrolments } from './pages/ExamEnrolments/ExamEnrolments';
import { Exemptions } from './pages/Exemptions/Exemptions';
import { FundamentalEnrolments } from './pages/FundamentalEnrolments/FundamentalEnrolments';
import { LogbookProgressions } from './pages/LogbookProgressions/LogbookProgressions';
import { OfficeRoles } from './pages/OfficeRoles/OfficeRoles';
import { Results } from './pages/Results/Results';
import { Students } from './pages/Students/Students';
import { App } from './App';
import registerServiceWorker from './registerServiceWorker';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const loginPath =
	'/authentication/login?returnUrl=' +
	encodeURIComponent(window.location.toString());

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<App />}>
			<Route
				element={
					<PrivateRoute
						loginPath={loginPath}
						limitedRoles={ALLOWED_WEB_ROLES}
					/>
				}
			>
				<Route path="/" element={<Navigate to="/Students" />} />
				<Route path="/Students" element={<Students />} />
				<Route path="/Absences" element={<Absences />} />
				<Route path="/ClassEnrolments" element={<ClassEnrolments />} />
				<Route path="/ClassCancellations" element={<ClassCancellation />} />
				<Route path="/CourseEnrolments" element={<CourseEnrolments />} />
				<Route
					path="/FundamentalEnrolments"
					element={<FundamentalEnrolments />}
				/>
				<Route path="/CourseCancellations" element={<CourseCancellation />} />
				<Route path="/ExamEnrolments" element={<ExamEnrolments />} />
				<Route path="/ExamCancellations" element={<ExamCancellations />} />
				<Route path="/Exemptions" element={<Exemptions />} />
				<Route path="/LogbookProgressions" element={<LogbookProgressions />} />
				<Route path="/OfficeRoles" element={<OfficeRoles />} />
				<Route path="/Results" element={<Results />} />
			</Route>
		</Route>
	)
);

root.render(<RouterProvider router={router} />);

registerServiceWorker();
