import { Modal } from '@icasdigital/icas.core.reactcomponents';

import { MENU_ITEMS } from '../../constants/constants';
import { useRoleContext } from '../../hooks/useRoleContext';
import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { Tabs } from '../Tabs/Tabs';
import { TabsContent, TabsList, TabsTrigger } from '../Tabs/TabsItems';

import { AbsencesStudentView } from './AbsencesStudentView';
import { ClassEnrolmentsStudentView } from './ClassEnrolmentsStudentView';
import { CourseEnrolmentsStudentView } from './CourseEnrolmentsStudentView';
import { ExamEnrolmentsStudentView } from './ExamEnrolmentsStudentView';
import { ExemptionsStudentView } from './ExemptionsStudentView';
import { FundamentalEnrolmentsStudentView } from './FundamentalsStudentView';
import { ResultsStudentView } from './ResultsStudentView';

import './StudentViewStyle.css';

type RenderTabTriggersProps = {
	isOldSyllabusRole: boolean | undefined;
};

const menuItemsNotNeeded = [
	'Students',
	'OfficeRoles',
	'LogbookProgressions',
	'ExamCancellations',
	'ClassCancellations',
	'CourseCancellations',
];

const renderTabTriggers = ({ isOldSyllabusRole }: RenderTabTriggersProps) => {
	if (!isOldSyllabusRole) {
		menuItemsNotNeeded.push('ClassEnrolments');
	} else {
		menuItemsNotNeeded.push('CourseEnrolments');
	}
	return Object.entries(MENU_ITEMS).map(([key, value]) => {
		if (menuItemsNotNeeded.includes(key)) {
			return null;
		}
		return (
			<TabsTrigger key={key} value={value.path}>
				{value.displayText}
			</TabsTrigger>
		);
	});
};

export const StudentView = () => {
	const { studentInfo, onClose, onTabsChange, viewType } =
		useStudentViewContext();

	const { isOldSyllabusRole } = useRoleContext();

	if (!studentInfo) {
		return null;
	}

	const tabTriggers = renderTabTriggers({ isOldSyllabusRole });

	const { name, contactNumber } = studentInfo;

	return (
		<Modal id="student-view" onConfirm={onClose} onClose={onClose}>
			<Modal.Header>
				{name} - {contactNumber}
			</Modal.Header>
			<div>
				<Tabs value={viewType} onValueChange={onTabsChange}>
					<TabsList>{tabTriggers}</TabsList>
					<TabsContent
						key={MENU_ITEMS.Exemptions.path}
						value={MENU_ITEMS.Exemptions.path}
					>
						<ExemptionsStudentView />
					</TabsContent>
					{isOldSyllabusRole ? (
						<TabsContent
							key={MENU_ITEMS.ClassEnrolments.path}
							value={MENU_ITEMS.ClassEnrolments.path}
						>
							<ClassEnrolmentsStudentView />
						</TabsContent>
					) : (
						<TabsContent
							key={MENU_ITEMS.CourseEnrolments.path}
							value={MENU_ITEMS.CourseEnrolments.path}
						>
							<CourseEnrolmentsStudentView />
						</TabsContent>
					)}
					<TabsContent
						key={MENU_ITEMS.FundamentalEnrolments.path}
						value={MENU_ITEMS.FundamentalEnrolments.path}
					>
						<FundamentalEnrolmentsStudentView />
					</TabsContent>
					<TabsContent
						key={MENU_ITEMS.Absences.path}
						value={MENU_ITEMS.Absences.path}
					>
						<AbsencesStudentView />
					</TabsContent>
					<TabsContent
						key={MENU_ITEMS.ExamEnrolments.path}
						value={MENU_ITEMS.ExamEnrolments.path}
					>
						<ExamEnrolmentsStudentView />
					</TabsContent>
					<TabsContent
						key={MENU_ITEMS.Results.path}
						value={MENU_ITEMS.Results.path}
					>
						<ResultsStudentView />
					</TabsContent>
				</Tabs>
			</div>
		</Modal>
	);
};
