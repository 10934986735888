import { useState } from 'react';

import {
	ColumnDef,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';

import { Info, InitialData } from '../types/Data';

type UseTableProps = {
	columns: ColumnDef<Info, any>[];
	data?: InitialData;
};

const emptyArray: InitialData = [];

export const useTable = ({ columns, data = emptyArray }: UseTableProps) => {
	const [globalFilter, setGlobalFilter] = useState('');

	const table = useReactTable({
		data,
		columns,
		state: {
			globalFilter,
		},
		enableSorting: true,
		enableMultiSort: true,
		enableSortingRemoval: true,
		onGlobalFilterChange: setGlobalFilter,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	});

	return { table, globalFilter, setGlobalFilter };
};
