import { useCallback, useEffect, useState } from 'react';

import {
	FetchHelper,
	FetchResponse,
	LoadingStatus,
	useAuthContext,
} from '@icasdigital/icas.core.reactcomponents';

import { User } from '../types/User';

import { isLoadedStatus } from './useGetData';

type UseUserReturn = {
	user: User | undefined;
};

type UseUserFunction = () => UseUserReturn;

export const useUser: UseUserFunction = () => {
	const { userId, handleUnauth, currentLoading } = useAuthContext();
	const [user, setUser] = useState<User>();
	const [loadingStatus, setLoadingStatus] = useState(
		LoadingStatus.IsNotLoading
	);

	const handleResponse = useCallback(
		(fetchResponse: FetchResponse): void => {
			if (fetchResponse.ok) {
				const data: any = fetchResponse.body;
				setUser(data as User);
				return setLoadingStatus(LoadingStatus.LoadedSuccessfully);
			}
			if (fetchResponse.status === 400 || fetchResponse.status === 500) {
				return setLoadingStatus(LoadingStatus.EndedWithError);
			}
			if (fetchResponse.status === 401) {
				setLoadingStatus(LoadingStatus.EndedWithError);
				return handleUnauth();
			}
			setLoadingStatus(LoadingStatus.EndedWithError);
		},
		[handleUnauth]
	);

	const getData = useCallback(async () => {
		setLoadingStatus(LoadingStatus.IsLoading);
		const fetchUrl = '/api/Contact/user';
		const fetchResponse = await FetchHelper.getJSON(
			fetchUrl.toString(),
			handleUnauth
		);
		handleResponse(fetchResponse);
	}, [handleResponse, handleUnauth]);

	useEffect(() => {
		const isFullyLoaded = userId && !user && isLoadedStatus(loadingStatus);
		if (isFullyLoaded) {
			getData();
		}
	}, [userId, getData, loadingStatus, user]);

	return { user };
};
