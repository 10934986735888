import { useRoleContext } from '../../hooks/useRoleContext';
import { FirmIcon } from '../FirmIcon';
import { OfficeIcon } from '../OfficeIcon';

import './TitleContainerStyle.css';

export const RoleChoice = () => {
	const { role, setRoleChoiceNeeded } = useRoleContext();

	if (!role) {
		return null;
	}

	const { OrganisationName: name, OrganisationType: type } = role;
	const icon = type === 'Office' ? <OfficeIcon /> : <FirmIcon />;

	return (
		<button
			className="role-header icas-button secondary"
			id="role-header"
			onClick={() => setRoleChoiceNeeded(true)}
		>
			{icon}
			{name}
		</button>
	);
};

export const TitleContainer = () => {
	const { role } = useRoleContext();

	const titleClassname = !role ? 'app-title' : 'app-title with-role';

	return (
		<div id="title-container">
			<RoleChoice />
			<h1 className={titleClassname}>ATO Firm Profile</h1>
		</div>
	);
};
