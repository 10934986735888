import { useMemo, useState } from 'react';
import { useExportableCSV } from 'use-exportable-csv';

import { LoadingStatus } from '@icasdigital/icas.core.reactcomponents';
import {
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';

import { DownloadButton } from '../../components/Actions/DownloadButton';
import { LevelSelect } from '../../components/Actions/LevelSelect';
import { PageSizeSelect } from '../../components/Actions/PageSizeSelect';
import { SearchBox } from '../../components/Actions/SearchBox';
import { MonthPicker } from '../../components/MonthPicker/MonthPicker';
import { StudentView } from '../../components/StudentView/StudentView';
import { Pagination } from '../../components/Table/Pagination';
import { DataTable } from '../../components/Table/Table';
import { Tabs } from '../../components/Tabs/Tabs';
import {
	TabsContent,
	TabsList,
	TabsTrigger,
} from '../../components/Tabs/TabsItems';
import {
	EMPTY_DATA,
	EXAM_TYPES,
	ORGANISATION_URLS,
} from '../../constants/constants';
import { useGetData } from '../../hooks/useGetData';
import { useLevel } from '../../hooks/useLevel';
import { useResultsData } from '../../hooks/useResultsData';
import { useRoleContext } from '../../hooks/useRoleContext';
import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { renderUrl, stripData } from '../../utils/Formatting';

import 'react-datepicker/dist/react-datepicker.css';
import '../ContainerStyle.css';

const date = new Date();
const month = date.getMonth();
const year = date.getFullYear();
const startDate = new Date(year, month, 1);

export const Results = () => {
	const { studentInfo } = useStudentViewContext();
	const showStudentView = studentInfo?.id !== undefined;

	const [resultType, setResultType] = useState(EXAM_TYPES.Exam);
	const [resultMonth, setResultMonth] = useState<Date>(startDate);

	const { level, setLevel } = useLevel();
	const [globalFilter, setGlobalFilter] = useState('');

	const { role } = useRoleContext();

	const url = useMemo(
		() =>
			!role
				? ''
				: renderUrl({
						id: role.OrganisationId,
						url: ORGANISATION_URLS.Results,
						levelCode: level,
						examTypeFilter: resultType,
						dateFilter: resultMonth,
				  }),
		[role, level, resultType, resultMonth]
	);

	const {
		initialResultsData,
		loadingStatus: initialDataLoaded,
		setLoadingStatus,
	} = useGetData({
		url,
		resultData: true,
	});
	const { loaded, formattedData, columns, setLoaded } = useResultsData({
		data: initialResultsData,
		initialDataLoaded,
	});

	const noData =
		loaded && (!formattedData.length || formattedData.length === 0);
	const csvData = noData ? EMPTY_DATA : stripData(formattedData);
	const options = { bom: true };
	const csvLink = useExportableCSV(csvData, options);

	const table = useReactTable({
		data: formattedData,
		columns: columns,
		state: {
			globalFilter,
		},
		enableSorting: true,
		enableMultiSort: true,
		enableSortingRemoval: true,
		onGlobalFilterChange: setGlobalFilter,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	});

	const onTabsChange = (value: string) => {
		setGlobalFilter('');
		setResultType(value);
		setLoadingStatus(LoadingStatus.IsNotLoading);
		setLoaded(false);
	};

	const onLevelChange = (level?: string) => {
		setLevel(level);
		setLoadingStatus(LoadingStatus.IsNotLoading);
		setLoaded(false);
	};

	const onMonthChange = (date: Date) => {
		setResultMonth(date);
		setLoadingStatus(LoadingStatus.IsNotLoading);
		setLoaded(false);
	};

	if (!formattedData) {
		return null;
	}

	return (
		<div className="app-container with-tabs" key={role?.OrganisationId}>
			{showStudentView ? <StudentView /> : null}
			<div>
				<h2 className="with-line">Results</h2>
				<p>
					All results released within the last year are available on the table
					below, filtered by release month. The month filter defaults to the
					current month. For a detailed exam result history, please see each
					individual students record.
				</p>
			</div>
			<div className="filter-container large">
				{noData ? <p className="no-results">No results found</p> : null}
				<div style={{ display: 'flex', gap: '24px' }}>
					<SearchBox
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
					<PageSizeSelect table={table} />
					<LevelSelect currentLevel={level} setLevel={onLevelChange} />
				</div>
				<div style={{ display: 'flex', gap: '24px' }}>
					<MonthPicker
						resultMonth={resultMonth}
						setResultMonth={onMonthChange}
					/>
					<DownloadButton csvLink={csvLink} dataName="Results" />
				</div>
			</div>
			<Tabs value={resultType} onValueChange={onTabsChange}>
				<TabsList>
					<TabsTrigger value={EXAM_TYPES.Exam}>Exam</TabsTrigger>
					<TabsTrigger value={EXAM_TYPES.Mock}>Mock</TabsTrigger>
					<TabsTrigger value={EXAM_TYPES.TOAK}>TOAK</TabsTrigger>
					<TabsTrigger value={EXAM_TYPES.PCT}>PCT</TabsTrigger>
					<TabsTrigger value={EXAM_TYPES.HEX}>HEX</TabsTrigger>
					<TabsTrigger value={EXAM_TYPES.MEX}>MEX</TabsTrigger>
					<TabsTrigger value={EXAM_TYPES.PT}>PT</TabsTrigger>
					<TabsTrigger value={EXAM_TYPES.PM}>PM</TabsTrigger>
					<TabsTrigger value={EXAM_TYPES.PQ}>PQ</TabsTrigger>
				</TabsList>
				<TabsContent value={EXAM_TYPES.Exam}>
					<DataTable table={table} className="first-tab-child" />
				</TabsContent>
				<TabsContent value={EXAM_TYPES.Mock}>
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value={EXAM_TYPES.TOAK}>
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value={EXAM_TYPES.PCT}>
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value={EXAM_TYPES.HEX}>
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value={EXAM_TYPES.MEX}>
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value={EXAM_TYPES.PT}>
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value={EXAM_TYPES.PM}>
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value={EXAM_TYPES.PQ}>
					<DataTable table={table} />
				</TabsContent>
			</Tabs>
			<Pagination table={table} />
		</div>
	);
};
