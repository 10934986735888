import React from 'react';
import { Outlet } from 'react-router-dom';

import { AuthProvider, Layout } from '@icasdigital/icas.core.reactcomponents';

import { Menu } from './components/Menu/Menu';
import { RoleContextProvider } from './components/RoleContextProvider';
import { RolePicker } from './components/RolePicker/RolePicker';
import { StudentViewProvider } from './components/StudentView/StudentViewProvider';
import { TitleContainer } from './components/TitleContainer/TitleContainer';

import './custom.css';

export const App = (): JSX.Element => (
	<AuthProvider>
		<Layout>
			<RoleContextProvider>
				<RolePicker />
				<StudentViewProvider>
					<TitleContainer />
					<div className="content-container">
						<Menu />
						<Outlet />
					</div>
				</StudentViewProvider>
			</RoleContextProvider>
		</Layout>
	</AuthProvider>
);
