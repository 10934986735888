import { flexRender, Table } from '@tanstack/react-table';

import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { Info } from '../../types/Data';

import './TableStyle.css';

export type TableProps = {
	table: Table<Info>;
	className?: string;
	enableRowClick?: boolean;
};

const minimumRows = 10;

export const DataTable = ({
	table,
	className,
	enableRowClick = true,
}: TableProps) => {
	const { onRowClick } = useStudentViewContext();

	const tableRowLength = table.getRowModel().rows.length;

	const emptyRowsNeeded =
		minimumRows - tableRowLength <= 0 ? 0 : minimumRows - tableRowLength;

	return (
		<div className={`table-container ${className}`}>
			<table>
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(header => (
								<th
									key={header.id}
									colSpan={header.colSpan}
									style={{ width: header.getSize() }}
									className={
										header.column.columns.length
											? 'header-column'
											: 'sub-column'
									}
								>
									{header.isPlaceholder ? null : (
										// eslint-disable-next-line jsx-a11y/click-events-have-key-events
										<div
											{...{
												className: header.column.getCanSort()
													? 'cursor-pointer select-none'
													: '',
												onClick: header.column.getToggleSortingHandler(),
												onKeyDown: header.column.getToggleSortingHandler(),
											}}
										>
											{flexRender(
												header.column.columnDef.header,
												header.getContext()
											)}
											{{
												asc: ' 🔼',
												desc: ' 🔽',
											}[header.column.getIsSorted() as string] ?? null}
										</div>
									)}
									<div
										{...{
											onMouseDown: header.getResizeHandler(),
											onTouchStart: header.getResizeHandler(),
											className: `resizer ${
												header.column.getIsResizing() ? 'isResizing' : ''
											}`,
											style: {
												transform: header.column.getIsResizing()
													? `translateX(${
															table.getState().columnSizingInfo.deltaOffset
													  }px)`
													: '',
											},
										}}
									/>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => (
						<tr
							key={row.id}
							onClick={() => {
								enableRowClick && onRowClick(row.original);
							}}
						>
							{row.getVisibleCells().map(cell => (
								<td key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
					{emptyRowsNeeded
						? Array.from({ length: emptyRowsNeeded }, (_v, i) => i).map(row => (
								<tr key={row} className="empty-row">
									<td key={`${row}-cell`}></td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
};
