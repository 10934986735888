import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Info } from '../types/Data';
import {
	datetimeSorting,
	renderBooleanString,
	renderCapitalisedString,
} from '../utils/Formatting';

const columnHelper = createColumnHelper<Info>();

const StudentColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('ContactNumber', {
		cell: info => info.getValue(),
		header: () => <span>Contact No.</span>,
		sortingFn: 'alphanumeric',
	}),
	columnHelper.accessor('FirstName', {
		cell: info => info.getValue(),
		header: () => <span>First Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('LastName', {
		cell: info => info.getValue(),
		header: () => <span>Last Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('OfficeCity', {
		cell: info => {
			const data = info.getValue();
			return renderCapitalisedString(data);
		},
		header: () => <span>Office City</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('CustomerType', {
		cell: info => info.getValue(),
		header: () => <span>Status</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('ContractStartDate', {
		cell: info => info.getValue(),
		header: () => <span>Contract Start Date</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('ContractEndDate', {
		cell: info => info.getValue(),
		header: () => <span>Contract End Date</span>,
		sortingFn: datetimeSorting,
	}),
];

const OfficeRolesColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('FullName', {
		cell: info => info.getValue(),
		header: () => <span>Contact</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('OfficeAddress', {
		cell: info => info.getValue(),
		header: () => <span>Office Address</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('OfficeCity', {
		cell: info => {
			const data = info.getValue();
			return renderCapitalisedString(data);
		},
		header: () => <span>Office City</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('Type', {
		cell: info => info.getValue(),
		header: () => <span>Role</span>,
		sortingFn: 'text',
	}),
];

const ExamCancellationsColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('StudentContactNumber', {
		cell: info => info.getValue(),
		header: () => <span>Contact No.</span>,
		sortingFn: 'alphanumeric',
	}),
	columnHelper.accessor('StudentName', {
		cell: info => info.getValue(),
		header: () => <span>Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentSubjectAcronym', {
		cell: info => info.getValue(),
		header: () => <span>Acronym</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentSubject', {
		cell: info => info.getValue(),
		header: () => <span>Subject</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentStartDate', {
		cell: info => info.getValue(),
		header: () => <span>Start Date</span>,
		sortingFn: datetimeSorting,
	}),
];

const AbsenceColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('StudentContactNumber', {
		cell: info => info.getValue(),
		header: () => <span>Contact No.</span>,
		sortingFn: 'alphanumeric',
	}),
	columnHelper.accessor('StudentName', {
		cell: info => info.getValue(),
		header: () => <span>Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('DateOfAbsence', {
		cell: info => info.getValue(),
		header: () => <span>Date of Absence</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('SubjectAcronym', {
		header: () => <span>Acronym</span>,
		sortingFn: 'text',
	}),
];

const ExemptionsColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('StudentContactNumber', {
		cell: info => info.getValue(),
		header: () => <span>Contact No.</span>,
		sortingFn: 'alphanumeric',
	}),
	columnHelper.accessor('StudentName', {
		cell: info => info.getValue(),
		header: () => <span>Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('ContractStartDate', {
		cell: info => info.getValue(),
		header: () => <span>Contract Start Date</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('ExemptionSubject', {
		cell: info => info.getValue(),
		header: () => <span>Subject</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('SubjectAcronym', {
		cell: info => info.getValue(),
		header: () => <span>Acronym</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('ExemptionType', {
		cell: info => info.getValue(),
		header: () => <span>Type</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('DecisionDate', {
		cell: info => info.getValue(),
		header: () => <span>Decision Date</span>,
		sortingFn: datetimeSorting,
	}),
];

const ClassEnrolmentColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('StudentContactNumber', {
		cell: info => info.getValue(),
		header: () => <span>Contact No.</span>,
		sortingFn: 'alphanumeric',
	}),
	columnHelper.accessor('StudentName', {
		cell: info => info.getValue(),
		header: () => <span>Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentSubjectAcronym', {
		header: () => <span>Acronym</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentSubject', {
		cell: info => info.getValue(),
		header: () => <span>Subject</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentLocation', {
		cell: info => {
			const data = info.getValue();
			return renderCapitalisedString(data);
		},
		header: () => <span>Location</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentStartDate', {
		cell: info => info.getValue(),
		header: () => <span>Class Start Date</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('EnrolmentEndDate', {
		cell: info => info.getValue(),
		header: () => <span>Class End Date</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('StatusReason', {
		cell: info => info.getValue(),
		header: () => <span>Status Reason</span>,
		sortingFn: 'text',
	}),
];

const CourseCancellationColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('StudentContactNumber', {
		cell: info => info.getValue(),
		header: () => <span>Contact No.</span>,
		sortingFn: 'alphanumeric',
	}),
	columnHelper.accessor('StudentName', {
		cell: info => info.getValue(),
		header: () => <span>Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('SubjectName', {
		cell: info => info.getValue(),
		header: () => <span>Subject</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('CourseStartDate', {
		cell: info => info.getValue(),
		header: () => <span>Course Start Date</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('CourseEndDate', {
		cell: info => info.getValue(),
		header: () => <span>Course End Date</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('TermName', {
		cell: info => info.getValue(),
		header: () => <span>Term</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('CourseProvider', {
		cell: info => info.getValue(),
		header: () => <span>Course Provider</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentType', {
		cell: info => info.getValue(),
		header: () => <span>Enrolment Type</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('SubEnrolmentType', {
		cell: info => info.getValue(),
		header: () => <span>Sub Enrolment Type</span>,
		sortingFn: 'text',
	}),
];

const CourseEnrolmentColumns: ColumnDef<Info, any>[] = [
	...CourseCancellationColumns,
	columnHelper.accessor('PercentageComplete', {
		cell: info => info.getValue(),
		header: () => <span>% Completed</span>,
		sortingFn: 'alphanumeric',
	}),
];

const ExamEnrolmentColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('StudentContactNumber', {
		cell: info => info.getValue(),
		header: () => <span>Contact No.</span>,
		sortingFn: 'alphanumeric',
	}),
	columnHelper.accessor('StudentName', {
		cell: info => info.getValue(),
		header: () => <span>Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentSubjectAcronym', {
		header: () => <span>Acronym</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentSubject', {
		cell: info => info.getValue(),
		header: () => <span>Subject</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentLocation', {
		cell: info => {
			const data = info.getValue();
			return renderCapitalisedString(data);
		},
		header: () => <span>Location</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('EnrolmentStartDate', {
		cell: info => info.getValue(),
		header: () => <span>Exam Start Date</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('StatusReason', {
		cell: info => info.getValue(),
		header: () => <span>Status Reason</span>,
		sortingFn: 'text',
	}),
];

const LogbookProgressionsColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('StudentName', {
		cell: info => info.getValue(),
		header: () => <span>Name</span>,
		sortingFn: 'text',
	}),
	{
		header: 'Competencies',
		columns: [
			columnHelper.accessor('Competencies', {
				cell: info => info.getValue(),
				header: () => <span>X/39</span>,
				sortingFn: 'alphanumeric',
			}),
			columnHelper.accessor('CompetenciesPercentage', {
				cell: info => info.getValue(),
				header: () => <span>%</span>,
				sortingFn: 'alphanumeric',
			}),
		],
	},
	{
		header: 'Employment Days',
		columns: [
			columnHelper.accessor('EmploymentDays', {
				cell: info => info.getValue(),
				header: () => <span>X/1095</span>,
				sortingFn: 'alphanumeric',
			}),
			columnHelper.accessor('EmploymentDaysPercentage', {
				cell: info => info.getValue(),
				header: () => <span>%</span>,
				sortingFn: 'alphanumeric',
			}),
		],
	},
	{
		header: 'Audit Days',
		columns: [
			columnHelper.accessor('AuditDays', {
				cell: info => info.getValue(),
				header: () => <span>X/210</span>,
				sortingFn: 'alphanumeric',
			}),
			columnHelper.accessor('AuditPercentage', {
				cell: info => info.getValue(),
				header: () => <span>%</span>,
				sortingFn: 'alphanumeric',
			}),
		],
	},
	{
		header: 'SA Days',
		columns: [
			columnHelper.accessor('StatutoryAuditDays', {
				cell: info => info.getValue(),
				header: () => <span> X/105</span>,
				sortingFn: 'alphanumeric',
			}),
		],
	},
	{
		header: 'RPE Days',
		columns: [
			columnHelper.accessor('RelevantPracticalExperienceDays', {
				cell: info => info.getValue(),
				header: () => <span>X/450</span>,
				sortingFn: 'alphanumeric',
			}),
		],
	},
	{
		header: 'Approvals',
		columns: [
			columnHelper.accessor('FinalApprovalReceived', {
				cell: info => {
					const value = info.getValue();
					return renderBooleanString(value);
				},
				header: () => <span>Final</span>,
				sortingFn: 'text',
			}),
			columnHelper.accessor('AuditApprovalReceived', {
				cell: info => {
					const value = info.getValue();
					return renderBooleanString(value);
				},
				header: () => <span>Audit</span>,
				sortingFn: 'text',
			}),
			columnHelper.accessor('IcasAuditApproved', {
				cell: info => {
					const value = info.getValue();
					return renderBooleanString(value);
				},
				header: () => <span>ICAS Audit</span>,
				sortingFn: 'text',
			}),
		],
	},
];

const ResultsColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('ExamType', {
		cell: info => info.getValue(),
		header: () => <span>Exam Type</span>,
		sortingFn: 'alphanumeric',
	}),
	columnHelper.accessor('ReleaseDate', {
		cell: info => info.getValue(),
		header: () => <span>Release Date</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('StudentContactNumber', {
		cell: info => info.getValue(),
		header: () => <span>Student No. </span>,
		sortingFn: 'alphanumeric',
	}),
	columnHelper.accessor('StudentName', {
		header: () => <span>Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('StudentOffice', {
		header: () => <span>Office</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('PassFail', {
		header: () => <span>Result</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('Grade', {
		header: () => <span>Grade</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('ExamDate', {
		header: () => <span>Exam Date</span>,
		sortingFn: datetimeSorting,
	}),
	columnHelper.accessor('Subject', {
		header: () => <span>Subject</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('SubjectAcronym', {
		header: () => <span>Acronym</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('AttemptNumber', {
		header: () => <span>Attempt</span>,
		sortingFn: 'text',
	}),
];

const FundamentalEnrolmentColumns: ColumnDef<Info, any>[] = [
	columnHelper.accessor('StudentName', {
		header: () => <span>Name</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('FundamentalName', {
		header: () => <span>Fundamental</span>,
		sortingFn: 'text',
	}),
	columnHelper.accessor('FundamentalEnrolmentProgress', {
		header: () => <span>Progress</span>,
		sortingFn: 'text',
	}),
];

export const Columns = {
	Absences: AbsenceColumns,
	CourseEnrolments: CourseEnrolmentColumns,
	FundamentalEnrolments: FundamentalEnrolmentColumns,
	CourseCancellations: CourseCancellationColumns,
	ClassEnrolments: ClassEnrolmentColumns,
	ClassCancellations: ClassEnrolmentColumns,
	ExamCancellations: ExamCancellationsColumns,
	ExamEnrolments: ExamEnrolmentColumns,
	Exemptions: ExemptionsColumns,
	LogbookProgressions: LogbookProgressionsColumns,
	OfficeRoles: OfficeRolesColumns,
	Results: ResultsColumns,
	Students: StudentColumns,
};
