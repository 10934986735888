import { useExportableCSV } from 'use-exportable-csv';

import { LoadingStatus } from '@icasdigital/icas.core.reactcomponents';

import { DownloadButton } from '../../components/Actions/DownloadButton';
import { LevelSelect } from '../../components/Actions/LevelSelect';
import { PageSizeSelect } from '../../components/Actions/PageSizeSelect';
import { SearchBox } from '../../components/Actions/SearchBox';
import { Loader } from '../../components/Loader';
import { StudentView } from '../../components/StudentView/StudentView';
import { Pagination } from '../../components/Table/Pagination';
import { DataTable } from '../../components/Table/Table';
import { Columns } from '../../constants/columns';
import { EMPTY_DATA, ORGANISATION_URLS } from '../../constants/constants';
import { useGetData } from '../../hooks/useGetData';
import { useLevel } from '../../hooks/useLevel';
import { useRoleContext } from '../../hooks/useRoleContext';
import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { useTable } from '../../hooks/useTable';
import { renderUrl, stripData } from '../../utils/Formatting';

import '../ContainerStyle.css';

export const CourseCancellation = () => {
	const { studentInfo } = useStudentViewContext();
	const showStudentView = studentInfo?.id !== undefined;

	const { level, setLevel } = useLevel();

	const { role, isOldSyllabusRole } = useRoleContext();

	const url = !role
		? ''
		: renderUrl({
				id: role.OrganisationId,
				url: ORGANISATION_URLS.CourseCancellations,
				levelCode: level,
		  });

	const { data, loadingStatus, setLoadingStatus } = useGetData({ url });

	const { table, globalFilter, setGlobalFilter } = useTable({
		columns: Columns.CourseCancellations,
		data,
	});

	const options = { bom: true };
	const noData = !data || !data.length;
	const csvData = noData ? EMPTY_DATA : stripData(data);
	const csvLink = useExportableCSV(csvData, options);

	const onLevelChange = (level?: string) => {
		setLevel(level);
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	const isNotLoaded =
		loadingStatus !== LoadingStatus.LoadedSuccessfully &&
		loadingStatus !== LoadingStatus.EndedWithError;

	if (isNotLoaded && !data) {
		return <Loader />;
	}

	if (isOldSyllabusRole) {
		return null;
	}

	return (
		<div className="app-container with-tabs">
			{showStudentView ? <StudentView /> : null}
			<div>
				<h2 className="with-line">Course Cancellations</h2>
				<p>
					Below you will find all students and/or apprentices who have cancelled
					enrolments to current and future courses.
				</p>
			</div>
			<div className="filter-container">
				{noData ? <p className="no-results">No results found</p> : null}
				<div style={{ display: 'flex', gap: '24px' }}>
					<SearchBox
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
					<LevelSelect
						currentLevel={level}
						setLevel={onLevelChange}
						levelType="Old"
					/>
					<PageSizeSelect table={table} />
				</div>
				<DownloadButton csvLink={csvLink} dataName="CourseCancellations" />
			</div>
			<DataTable table={table} />
			<Pagination table={table} />
		</div>
	);
};
