import { Select } from '@icasdigital/icas.core.reactcomponents';

import { TableProps } from '../Table/Table';

type PageSizeSelectProps = Pick<TableProps, 'table'>;

const pageSizeOptions = ['10', '20', '30', '40', '50'];

export const PageSizeSelect = ({ table }: PageSizeSelectProps) => {
	if (table.options.data.length <= 10) {
		return null;
	}
	return (
		<Select
			id="page-size"
			labelText={'Page Size:'}
			handleChange={(_id: string, value: string | number) => {
				table.setPageSize(Number(value));
			}}
			selectedOption={table.getState().pagination.pageSize}
			options={pageSizeOptions}
		/>
	);
};
