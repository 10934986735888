import { ExamType } from '../types/ExamType';
import { LevelOptionSets } from '../types/LevelOptions';
import {
	OrganisationUrlOptions,
	PageOptionsType,
	StudentUrlOptions,
	TabOption,
} from '../types/UrlOptions';

export const ALLOWED_WEB_ROLES = ['Firm Profile User'];

export const EMPTY_DATA = [{}];

export const MENU_ITEMS: Readonly<Record<PageOptionsType, TabOption>> = {
	Students: { displayText: 'Students', path: 'Students' },
	OfficeRoles: { displayText: 'Office Roles', path: 'OfficeRoles' },
	Results: { displayText: 'Results', path: 'Results' },
	Absences: { displayText: 'Absences', path: 'Absences' },
	Exemptions: { displayText: 'Exemptions', path: 'Exemptions' },
	ClassCancellations: {
		displayText: 'Class Cancellations',
		path: 'ClassCancellations',
	},
	ClassEnrolments: { displayText: 'Class Enrolments', path: 'ClassEnrolments' },
	FundamentalEnrolments: {
		displayText: 'Fundamentals',
		path: 'FundamentalEnrolments',
	},
	CourseCancellations: {
		displayText: 'Course Cancellations',
		path: 'CourseCancellations',
	},
	CourseEnrolments: {
		displayText: 'Course Enrolments',
		path: 'CourseEnrolments',
	},
	LogbookProgressions: {
		displayText: 'Logbooks',
		path: 'LogbookProgressions',
	},
	ExamEnrolments: { displayText: 'Exam Enrolments', path: 'ExamEnrolments' },
	ExamCancellations: {
		displayText: 'Exam Cancellations',
		path: 'ExamCancellations',
	},
};

export const EXAM_TYPES: Readonly<Record<ExamType, string>> = {
	Exam: '605340000',
	Mock: '605340001',
	PT: '605340002',
	TOAK: '605340003',
	PCT: '605340004',
	HEX: '605340005',
	MEX: '605340006',
	PM: '605340008',
	PQ: '605340010',
};

export const ORGANISATION_URLS: Readonly<
	Record<OrganisationUrlOptions, string>
> = {
	ClassAbsences: 'Absences/organisation/class/{id}?levelCodeFilter={level}',
	ExamAbsences: 'Absences/organisation/exam/{id}?levelCodeFilter={level}',
	WorkshopAbsences:
		'Absences/organisation/workshop/{id}?levelCodeFilter={level}',
	ClassEnrolments: 'ClassEnrolment/organisation/{id}?levelCodeFilter={level}',
	ParentClassEnrolments:
		'ClassEnrolment/parent/organisation/{id}?levelCodeFilter={level}',
	ClassCancellations:
		'ClassEnrolment/organisation/cancelled/{id}?levelCodeFilter={level}',
	ParentClassCancellations:
		'ClassEnrolment/parent/organisation/cancelled/{id}?levelCodeFilter={level}',
	CourseEnrolments: 'CourseEnrolment/organisation/{id}?levelCodeFilter={level}',
	CourseCancellations:
		'CourseEnrolment/organisation/cancelled/{id}?levelCodeFilter={level}',
	FundamentalEnrolments: 'FundamentalEnrolment/organisation/{id}',
	Exemptions: 'Exemptions/organisation/{id}',
	ExamCancellations:
		'ExamEnrolment/organisation/cancelled/{id}?levelCodeFilter={level}',
	ExamEnrolments: 'ExamEnrolment/organisation/{id}?levelCodeFilter={level}',
	LogbookProgressions: 'Contact/{id}/students/subjectProgress',
	OfficeRoles: 'Contact/{id}/atocontacts',
	Results:
		'ExamResults/organisation/{id}?examTypeFilter={exam}&levelCodeFilter={level}&dateFilter={date}',
	Students: 'Contact/{id}/students',
};

export const STUDENT_URLS: Readonly<Record<StudentUrlOptions, string>> = {
	ClassAbsences: 'Absences/student/class/{id}?levelCodeFilter={level}',
	ExamAbsences: 'Absences/student/exam/{id}?levelCodeFilter={level}',
	WorkshopAbsences: 'Absences/student/workshop/{id}?levelCodeFilter={level}',
	ParentClassEnrolments: 'ClassEnrolment/parent/student/{id}',
	ClassEnrolments: 'ClassEnrolment/student/{id}',
	CourseEnrolments: 'CourseEnrolment/student/{id}',
	FundamentalEnrolments: 'FundamentalEnrolment/student/{id}',
	Exemptions: 'Exemptions/individual/{id}',
	ExamEnrolments: 'ExamEnrolment/student/{id}?levelCodeFilter={level}',
	LogbookProgressions: 'Contact/{id}/students/subjectProgress',
	Results:
		'ExamResults/individual/{id}?examTypeFilter={exam}&levelCodeFilter={level}&dateFilter={date}',
};

export const OLD_LEVEL_CODES: LevelOptionSets = [
	{ name: 'TC', value: '605340000' },
	{ name: 'TPS', value: '605340001' },
	{ name: 'TPE', value: '605340002' },
] as const;

export const NEW_LEVEL_CODES: LevelOptionSets = [
	{ name: 'Knowledge', value: '605340004' },
	{ name: 'Skills', value: '605340005' },
	{ name: 'Integration', value: '605340006' },
];

export const LEVEL_CODES: LevelOptionSets = [
	...NEW_LEVEL_CODES,
	...OLD_LEVEL_CODES,
];
