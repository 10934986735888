import { OptionSet, Select } from '@icasdigital/icas.core.reactcomponents';

import {
	LEVEL_CODES,
	NEW_LEVEL_CODES,
	OLD_LEVEL_CODES,
} from '../../constants/constants';
import {
	LevelOptions,
	LevelOptionSets,
	LevelType,
} from '../../types/LevelOptions';

type LevelSelectProps = {
	currentLevel: string | undefined;
	setLevel: (level: LevelOptions) => void;
	levelType?: LevelType;
};

type Values = Record<LevelType, LevelOptionSets>;

const levelOptions: Values = {
	New: NEW_LEVEL_CODES,
	Old: OLD_LEVEL_CODES,
	Both: LEVEL_CODES,
};

const AllOption: OptionSet = {
	name: 'All',
	value: 0,
};

export const LevelSelect = ({
	currentLevel,
	setLevel,
	levelType = 'Both',
}: LevelSelectProps) => {
	const options = [...levelOptions[levelType], AllOption];

	return (
		<Select
			id="level-select"
			labelText={'Level:'}
			handleChange={(_id: string, value: string | number) => {
				setLevel(value as LevelOptions);
			}}
			selectedOption={currentLevel}
			options={options}
		/>
	);
};
