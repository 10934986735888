import { useCallback, useEffect, useRef, useState } from 'react';

import {
	FetchHelper,
	FetchResponse,
	LoadingStatus,
	useAuthContext,
} from '@icasdigital/icas.core.reactcomponents';

import { InitialData } from '../types/Data';
import { InitialResultsData } from '../types/Result';

import { useRoleContext } from './useRoleContext';

type UseGetDataProps = {
	url: string;
	resultData?: boolean;
};

export const isLoadedStatus = (loadingStatus: LoadingStatus): boolean =>
	loadingStatus !== LoadingStatus.IsLoading &&
	loadingStatus !== LoadingStatus.EndedWithError &&
	loadingStatus !== LoadingStatus.LoadedSuccessfully;

export const useGetData = ({ url, resultData }: UseGetDataProps) => {
	const [data, setData] = useState<InitialData>();
	const { role } = useRoleContext();
	const currentRoleRef = useRef<string>();
	const initialDataLoadRef = useRef(true);
	const [initialResultsData, setInitialResultsData] =
		useState<InitialResultsData>();
	const [loadingStatus, setLoadingStatus] = useState(
		LoadingStatus.IsNotLoading
	);
	const { userId, handleUnauth } = useAuthContext();

	//#region Request Handlers
	const handleResponse = useCallback(
		(fetchResponse: FetchResponse): void => {
			if (fetchResponse.ok) {
				const data: any =
					fetchResponse.status === 204 ? [] : fetchResponse.body;
				if (resultData) {
					setInitialResultsData(data as InitialResultsData);
				} else {
					setData(data as InitialData);
				}
				return setLoadingStatus(LoadingStatus.LoadedSuccessfully);
			}
			if (fetchResponse.status === 400 || fetchResponse.status === 500) {
				return setLoadingStatus(LoadingStatus.EndedWithError);
			}
			if (fetchResponse.status === 401) {
				setLoadingStatus(LoadingStatus.EndedWithError);
				return handleUnauth();
			}
			setLoadingStatus(LoadingStatus.EndedWithError);
			//set generic message
		},
		[handleUnauth, resultData]
	);

	const getData = useCallback(async () => {
		setLoadingStatus(LoadingStatus.IsLoading);
		const fetchUrl = `/api/${url}`;
		const fetchResponse = await FetchHelper.getJSON(
			fetchUrl.toString(),
			handleUnauth
		);
		handleResponse(fetchResponse);
		initialDataLoadRef.current = false;
	}, [handleResponse, handleUnauth, url]);

	useEffect(() => {
		const needsData =
			userId && role && role.OrganisationId && isLoadedStatus(loadingStatus);
		if (needsData && initialDataLoadRef.current === false) {
			setData([]);
		}
		if (needsData) {
			getData();
		}
	}, [userId, getData, loadingStatus, role, data]);

	useEffect(() => {
		if (role && role?.OrganisationId !== currentRoleRef.current) {
			currentRoleRef.current = role.OrganisationId;
			setLoadingStatus(LoadingStatus.IsNotLoading);
		}
	}, [role]);

	useEffect(() => {
		if (role && role?.OrganisationId !== currentRoleRef.current) {
			currentRoleRef.current = role.OrganisationId;
			setLoadingStatus(LoadingStatus.IsNotLoading);
		}
	}, [role]);

	return { data, initialResultsData, loadingStatus, setLoadingStatus };
};
